import React from "react";
import Coming from "../../home/comingsoon/Coming";

const Library = () => {
  return (
    <div className="library">
      <Coming />
    </div>
  );
};

export default Library;

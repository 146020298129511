import React from "react";
import Coming from "../../home/comingsoon/Coming";

const Trade = () => {
  return (
    <div className="trade">
      <Coming />
    </div>
  );
};

export default Trade;

import React from "react";
import Coming from "../../home/comingsoon/Coming";

const Connections = () => {
  return (
    <div className="connections">
      <Coming />
    </div>
  );
};

export default Connections;

import React from "react";
import Coming from "../../home/comingsoon/Coming";

const Swap = () => {
  return (
    <div className="swap">
      <Coming />
    </div>
  );
};

export default Swap;
